import React from 'react';
import './StickyBanner.css';

const text =
  'W dniach 30.09-14.10.2024 przebywam na urlopie. W przypadku chęci zgłoszenia się na zajęcia lub konsultacje proszę o wypełnienie formularza na dole strony. Skontaktuję się niezwłocznie po powrocie.';
export const StickyBanner = () => {
  return (
    <div className="stickyBanner">
      {/* <div>PROMOCJA</div> */}
      <div>{text}</div>
    </div>
  );
};
